export const Article = {
  ContentSliceTypes: {
    QUOTE: "PrismicArticleDataBodyQuote",
    TEXT: "PrismicArticleDataBodyText",
    CALLOUT: "PrismicArticleDataBodyCallout",
    TEXT_WITH_QUOTE: "PrismicArticleDataBodyTextWithQuote",
    IMAGES: "PrismicArticleDataBodyImages",
    IMAGES_WITH_QUOTE: "PrismicArticleDataBodyImagesWithQuote",
    IMAGE_GALLERY: "PrismicArticleDataBodyImageGallery",
    DIVIDER: "PrismicArticleDataBodyDivider",
    IFRAME: "PrismicArticleDataBodyIframeEmbed"
  },
  StyleAlignmentText: {
    LEFT: "left",
    CENTER: "center",
  },
  StylePositionQuote: {
    LEFT: "left",
    RIGHT: "right",
    CENTER: "center",
  },
  StylePositionTextWithQuote: {
    TEXT_LEFT_QUOTE_RIGHT: "text-left-quote-right",
    TEXT_RIGHT_QUOTE_LEFT: "text-right-quote-left",
  },
  StylePositionImagesWithQuote: {
    IMAGES_LEFT_QUOTE_RIGHT: "images-left-quote-right",
    IMAGES_RIGHT_QUOTE_LEFT: "images-right-quote-left",
  },
  StyleAlignmentImages: {
    FULL_WIDTH: "full-width",
    CONTAINED: "contained",
  }
}
