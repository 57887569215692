/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import * as React from "react"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import { QueryClient, QueryClientProvider, setLogger } from "react-query"
import { FormAlertProvider } from "./src/hocs/FormAlertProvider"

setLogger({
  log: () => {},

  warn: () => {},

  error: () => {},
})

const queryClient = new QueryClient()
export const wrapRootElement = ({ element }) => (
  <FormAlertProvider>
    <QueryClientProvider client={queryClient}>
      <PrismicPreviewProvider initialEnabled={true}>
        {element}
      </PrismicPreviewProvider>
    </QueryClientProvider>
  </FormAlertProvider>
)
