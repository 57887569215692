import React, { useState, useEffect, useContext } from "react"
import { PropTypes } from "prop-types"

const FormAlertContext = React.createContext({
  alertState: {
    result: null,
    msg: null,
    isVisible: false,
  },
  updateAlertState: () => {},
})

export const useFormAlertContext = () => useContext(FormAlertContext)

export const FormAlertProvider = ({ children }) => {
  const [alertState, updateAlertState] = useState({
    result: null,
    msg: null,
    isVisible: false,
  })

  useEffect(() => {
    if (alertState.isVisible) {
      const timer = setTimeout(
        () =>
          updateAlertState(prevState => ({ ...prevState, isVisible: false })),
        5000
      )

      return () => clearTimeout(timer)
    }
  }, [alertState.isVisible])

  return (
    <FormAlertContext.Provider value={{ alertState, updateAlertState }}>
      {children}
    </FormAlertContext.Provider>
  )
}

FormAlertProvider.propTypes = {
  children: PropTypes.node,
}
