const { Article } = require("../enums/Article")
const { lighten } = require("@theme-ui/color")

// As a percentage
const mobilePageContentWidth = 87.5
const desktopPageContentWidth = 87.5
// In rem
const desktopArticleContentWidth = 50

const basePageStyles = {
  // The desktop design is according to a 12 column-grid
  // Some components will stretch across the full grid
  // (eg. nav bar and feature carousel), but others
  // will only take 10 out of the 12 grids
  // Usually these are components will be the main
  // content of the site, hence `contentContainer`
  // However we probably want to keep background images
  // within contentContainers to the full-width of
  // the grid as well, instead of 10 over 12
  // So we need to use padding to constrain width
  container: {
    width: "100vw",
    maxWidth: "100%",
    // Keep within 1920px  or 120rem, with a 16px font base, on tablet/tdesktop
    px: [0, 0, "calc((100vw - 120rem)/2)"],
  },
  contentContainer: {
    // Keep within 10 over 12 of parent container width
    width: [`${mobilePageContentWidth}%`, `${desktopPageContentWidth}%`],
    mx: "auto",
  },
}

const articlePageStyles = {
  container: {
    base: {
      width: "100vw",
      maxWidth: "100%",
      margin: "auto",
      my: 4,
      // We keep the content width to a fixed pixel value above mobile
      // So that the length is comfortable even on large screens
      px: [
        `${(100 - mobilePageContentWidth) / 2}%`,
        `${(100 - mobilePageContentWidth) / 2}%`,
        `${(100 - mobilePageContentWidth) / 2}%`,
        `calc((100vw - ${desktopArticleContentWidth}rem)/2)`,
      ],
    },
    [Article.StyleAlignmentImages.FULL_WIDTH]: {
      px: 0,
    },
  },
  // For elements where padding doesn't work in
  // constraining width (eg. images, hr)
  // and that we don't want to have to
  // wrap in a parent container with padding
  containedElement: {
    // Should be complementary to the container px above
    width: [
      `${mobilePageContentWidth}%`,
      `${mobilePageContentWidth}%`,
      `${mobilePageContentWidth}%`,
      `${desktopArticleContentWidth}rem`,
    ],
    marginX: "auto",
  },
  quoteWithElement: {
    quoteLeft: {
      pr: [4, 4, 7],
      pb: 3,
    },
    quoteRight: {
      pl: [4, 4, 7],
      pb: 3,
    },
  },
}

const themeBreakpointWidths = {
  sm: "40em",
  md: "57em",
  lg: "65em",
}

const baseLinkStyles = {
  fontFamily: "body",
  textDecoration: "none",
  margin: 1,
}

const baseButtonLinkStyles = {
  ...baseLinkStyles,
  padding: 2,
  borderStyle: "solid",
  borderWidth: 2,
  borderRadius: "4px",
  cursor: "pointer",
}

const baseAlertStyles = {
  zIndex: 1,
  borderStyle: "solid",
  borderWidth: "2px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  position: "fixed",
  bottom: 4,
  left: "50%",
  transform: "translate(-50%)",
  transition: "all 0.2s ease-in",
}

const baseInputStyles = {
  px: 0,
  border: "none",
  borderRadius: 0,
  borderBottomStyle: "solid",
  borderBottomWidth: "1px",
  fontFamily: "body",
  fontSize: 1,
  "&::placeholder": {
    opacity: 0.9,
  },
}

const baseSelectStyles = {
  px: 0,
  border: "none",
  borderRadius: 0,
  borderBottomStyle: "solid",
  borderBottomWidth: "1px",
  fontFamily: "body",
  fontSize: 1,
  backgroundColor: "transparent",
  color: "rgba(255,255,255, 0.9)",
  option: { color: "text" },
  "&::placeholder": {
    opacity: 0.9,
  },
}

module.exports = {
  mobilePageContentWidth,
  desktopPageContentWidth,
  desktopArticleContentWidth,
  basePageStyles,
  articlePageStyles,
  space: [0, 4, 8, 16, 24, 32, 40, 48, 64],
  sizes: [0, 4, 8, 16, 24, 32, 40, 48],
  fonts: {
    body: '"newsreader", serif',
    heading: '"newsreader", serif',
    mono: "monospace",
  },
  fontSizes: [18, 20, 22, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 600,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  colors: {
    text: "#323130",
    background: "#F7F0EA",
    gray: "#605E5C",
    darkGray: "#323130",
    leafGreen: "#B9D13F",
    primary: "#B9D13F",
    oliveGreen: "#71853A",
    muted: "#71853A",
    soilBrown: "#94675B",
    secondary: "#94675B",
    darkSoilBrown: "#3B2913",
    beetrootRed: "#B1445E",
    sandYellow: "#EBC875",
    accent: "#EBC875",
    skyBlue: "#9FEDEF",
    highlight: "#9FEDEF",
    success: "#b8daa2",
    error: "#c22b5d",
    errorLight: lighten("#c22b5d", 0.3),
  },
  opacities: [0, 0.25, 0.33, 0.5, 0.66, 0.75, 0.8, 1],
  text: {
    fineprint: {
      fontFamily: "body",
      fontSize: 0,
      fontWeight: "body",
      lineHeight: "body",
    },
    body: {
      fontFamily: "body",
      fontSize: 1,
      fontWeight: "body",
      lineHeight: "body",
    },
    error: {
      fontFamily: "body",
      fontSize: 1,
      fontWeight: "body",
      lineHeight: "body",
      color: "error",
      margin: "revert",
      a: {
        textDecoration: "none",
        color: "error",
        fontWeight: "bold",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    quote: {
      color: "gray",
      fontFamily: "body",
      fontSize: 2,
      fontWeight: "body",
      lineHeight: "body",
    },
    quoteAttribution: {
      color: "gray",
      fontFamily: "body",
      fontSize: 1,
      fontWeight: "body",
      lineHeight: "body",
    },
    subheading: {
      fontFamily: "body",
      fontSize: 2,
      fontWeight: "bold",
      lineHeight: "body",
    },
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
    },
    headingOlive: {
      fontSize: [4, 4, 5],
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      color: "oliveGreen",
    },
    headingSandYellow: {
      fontSize: [4, 4, 5],
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      color: "sandYellow",
    },
    headingWhite: {
      fontSize: [4, 4, 5],
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      color: "white",
    },
    headingArticle: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      bg: "darkSoilBrown",
      color: "white",
      position: "relative",
      zIndex: 1,
      mt: -5,
      py: 3,
      px: 4,
      width: "90%",
    },
    headingMasthead: {
      fontSize: [4, 4, 5, 6],
      fontFamily: "heading",
      fontWeight: 400,
      lineHeight: "heading",
      color: "white",
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "caps",
    },
    subtitle: {
      fontStyle: "italic",
    },
  },
  radii: ["0px", "2px", "4px", "8px", "16px"],
  borderWidths: [0, 1, 2, 3],
  breakpointWidths: { ...themeBreakpointWidths },
  breakpoints: [
    // The default <html> font size is 16px, so the
    // following breakpoints are equivalent to:

    // Before the first breakpoint: Mobile portrait
    `@media (min-width: ${themeBreakpointWidths.sm}) and (orientation: landscape)`,
    // After the first breakpoint: Mobile landscape
    `@media (min-width: ${themeBreakpointWidths.sm}) and (orientation: portrait), (min-width: ${themeBreakpointWidths.md})`,
    // After the second breakpoint Tablet portrait and Small Tablet landscape
    themeBreakpointWidths.lg,
    // After the third breakpoint, large tablet landscape, desktops/TVs
  ],
  links: {
    card: {
      ...baseLinkStyles,
      display: "block",
      color: "text",
      transition: "all 0.2s ease-in",
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          filter: "opacity(0.85)",
        },
      },
    },
    iconDark: {
      ...baseLinkStyles,
      color: "text",
      transition: "all 0.1s ease-in",
      // Make sure to apply hover styles only on desktop
      // as they end up stuck on the element after tapping in mobile
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "oliveGreen",
          fontWeight: "bold",
        },
      },
    },
    iconWhite: {
      ...baseLinkStyles,
      color: "white",
      transition: "all 0.1s ease-in",
      // Make sure to apply hover styles only on desktop
      // as they end up stuck on the element after tapping in mobile
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "primary",
          fontWeight: "bold",
        },
      },
    },
    textDark: {
      ...baseLinkStyles,
      color: "text",
      transition: "all 0.1s ease-in",
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "oliveGreen",
          fontWeight: "bold",
          borderBottom: "2px solid",
          borderColor: "oliveGreen",
          pb: "1px",
        },
      },
    },
    textWhite: {
      ...baseLinkStyles,
      color: "white",
      transition: "all 0.1s ease-in",
      textDecoration: "underline",
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "primary",
          fontWeight: "bold",
          pb: "1px",
        },
      },
    },
    buttonPrimary: {
      ...baseButtonLinkStyles,
      display: "inline-block",
      color: "text",
      bg: "primary",
      borderColor: "primary",
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "primary",
          bg: "transparent",
        },
      },
    },
    buttonPrimaryTransparent: {
      ...baseButtonLinkStyles,
      display: "inline-block",
      color: "primary",
      bg: "transparent",
      borderColor: "primary",
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "text",
          bg: "primary",
        },
      },
    },
    buttonOlive: {
      ...baseButtonLinkStyles,
      display: "inline-block",
      color: "white",
      bg: "oliveGreen",
      borderColor: "oliveGreen",
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "oliveGreen",
          bg: "transparent",
        },
      },
    },
    buttonOliveTransparent: {
      ...baseButtonLinkStyles,
      display: "inline-block",
      color: "oliveGreen",
      bg: "transparent",
      borderColor: "oliveGreen",
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "white",
          bg: "oliveGreen",
        },
      },
    },
    buttonSandYellowTransparent: {
      ...baseButtonLinkStyles,
      display: "inline-block",
      color: "sandYellow",
      bg: "transparent",
      borderColor: "sandYellow",
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "text",
          bg: "sandYellow",
        },
      },
    },
    pagination: {
      ...baseLinkStyles,
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "oliveGreen",
      bg: "transparent",
      border: "none",
      borderRadius: 3,
      width: "35px",
      height: "35px",
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "white",
          bg: "oliveGreen",
        },
      },
    },
  },
  buttons: {
    reset: {
      backgroundColor: "transparent",
      border: "none",
      p: 0,
      font: "inherit",
      alignItems: "flex-start",
      cursor: "pointer",
      outline: "none",
    },
    primary: {
      ...baseButtonLinkStyles,
      display: "inline-block",
      color: "text",
      bg: "primary",
      borderColor: "primary",
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "primary",
          bg: "transparent",
        },
      },
    },
    primaryTransparent: {
      ...baseButtonLinkStyles,
      m: 0,
      color: "primary",
      bg: "transparent",
      borderColor: "primary",
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "text",
          bg: "primary",
        },
      },
    },
    olive: {
      ...baseButtonLinkStyles,
      display: "inline-block",
      color: "white",
      bg: "oliveGreen",
      borderColor: "oliveGreen",
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "oliveGreen",
          bg: "transparent",
        },
      },
    },
    oliveTransparent: {
      ...baseButtonLinkStyles,
      display: "inline-block",
      color: "oliveGreen",
      bg: "transparent",
      borderColor: "oliveGreen",
      "@media (hover: hover) and (pointer: fine)": {
        "&:hover": {
          color: "white",
          bg: "oliveGreen",
        },
      },
    }
  },
  cards: {
    primary: {
      display: "flex",
      borderRadius: 3,
      p: 0,
      bg: "white",
      overflow: "hidden",
    },
    journazine: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      p: 0,
    },
  },
  alerts: {
    default: {
      ...baseAlertStyles,
      color: "white",
      bg: "darkSoilBrown",
      borderColor: "background",
    },
    success: {
      ...baseAlertStyles,
      color: "text",
      bg: "success",
      borderColor: "success",
    },
    error: {
      ...baseAlertStyles,
      color: "white",
      bg: "error",
      borderColor: "error",
    },
  },
  forms: {
    labelWhite: {
      color: "white",
      fontSize: 0,
    },
    labelOlive: {
      color: "oliveGreen",
      fontSize: 0,
    },
    inputWhiteNoLabel: {
      ...baseInputStyles,
      pt: 3,
      color: "white",
      borderBottomColor: "white",
    },
    inputWhite: {
      ...baseInputStyles,
      color: "white",
      borderBottomColor: "white",
      label: {
        fontSize: 0,
      },
    },
    inputOlive: {
      ...baseInputStyles,
      color: "oliveGreen",
      borderBottomColor: "oliveGreen",
      label: {
        color: "oliveGreen",
        fontSize: 0,
      },
    },
    selectWhite: {
      ...baseSelectStyles,
      borderBottomColor: "white",
    },
    selectWhiteNoLabel: {
      ...baseSelectStyles,
      pt: 3,
      borderBottomColor: "white",
    },
    selectOlive: {
      ...baseSelectStyles,
      color: "oliveGreen",
      borderBottomColor: "oliveGreen",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 5,
    },
    h2: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 4,
    },
    h3: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 3,
    },
    h4: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 2,
    },
    h5: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 1,
    },
    h6: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 0,
    },
    p: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    a: {
      color: "primary",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
  },
}
