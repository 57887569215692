exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-article-page-template-with-query-jsx": () => import("./../../../src/templates/ArticlePageTemplateWithQuery.jsx" /* webpackChunkName: "component---src-templates-article-page-template-with-query-jsx" */),
  "component---src-templates-articles-page-template-with-query-jsx": () => import("./../../../src/templates/ArticlesPageTemplateWithQuery.jsx" /* webpackChunkName: "component---src-templates-articles-page-template-with-query-jsx" */),
  "component---src-templates-custom-page-template-with-query-jsx": () => import("./../../../src/templates/CustomPageTemplateWithQuery.jsx" /* webpackChunkName: "component---src-templates-custom-page-template-with-query-jsx" */),
  "component---src-templates-home-page-template-with-query-jsx": () => import("./../../../src/templates/HomePageTemplateWithQuery.jsx" /* webpackChunkName: "component---src-templates-home-page-template-with-query-jsx" */),
  "component---src-templates-journazine-page-template-with-query-jsx": () => import("./../../../src/templates/JournazinePageTemplateWithQuery.jsx" /* webpackChunkName: "component---src-templates-journazine-page-template-with-query-jsx" */),
  "component---src-templates-journazines-page-template-with-query-jsx": () => import("./../../../src/templates/JournazinesPageTemplateWithQuery.jsx" /* webpackChunkName: "component---src-templates-journazines-page-template-with-query-jsx" */),
  "component---src-templates-product-page-template-with-query-jsx": () => import("./../../../src/templates/ProductPageTemplateWithQuery.jsx" /* webpackChunkName: "component---src-templates-product-page-template-with-query-jsx" */)
}

